import * as React from 'react';
import { css } from '@emotion/react';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';

const Portfolio: React.FC<{ url: string; children?: React.ReactNode }> = ({ url, children }) => {
  return (
    <div
      css={css`
        border-radius: 10px;
        overflow: clip;
        width: 400px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2)
      `}
    >
      <img
        src={url}
        width="400"
        css={css`
          display: block;
        `}
      />
      <div
        css={css`
          background-color: #eef4ee;
          padding: 1rem;
          height: 100%;
        `}
      >
        {children}
      </div>
    </div>
  );
};

const PortfolioPage: React.FC = () => {
  return (
    <>
      <Header />
      <main
        css={css`
          padding: 3rem;
          flex-grow: 1;
          h1 {
            font-size: 3rem;
            font-weight: 500;
            margin: 0;
          }
        `}
      >
        <h1>Portfolio</h1>
        <p>Here are a few of the publicly accessible sites we have developed.</p>
        <div
          css={css`
            width: fit-content;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            @media only screen and (min-width: 57rem) {
              grid-template-columns: repeat(2, 1fr);
            }
            @media only screen and (min-width: 86rem) {
              grid-template-columns: repeat(3, 1fr);
            }
            gap: 2rem;
            grid-auto-rows: minmax(100px, auto);
            grid-auto-flow: row;
          `}
        >
          <Portfolio url="/portfolio/46thcoventry.png">
            <h2>46th Coventry Scouts</h2>
            <p><a href="https://46thcoventry.com">46thcoventry.com</a></p>
          </Portfolio>
          <Portfolio url="/portfolio/planning_portal.png">
            <h2>Planning Portal</h2>
            <p><a href="https://planningportal.co.uk">planningportal.co.uk</a></p>
          </Portfolio>
          <Portfolio url="/portfolio/events_week.png">
            <h2>Events Week Planning Guide</h2>
            <p><a href="https://eventsweek.com">eventsweek.com</a></p>
            <p>This site is currently in active development.</p>
          </Portfolio>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default PortfolioPage;

export const Head = () => {
  return <title>Portfolio - Green Chestnut</title>;
};
